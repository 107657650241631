import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "shader", "toggleButton"];
  static values = {
    collapsed: { type: Boolean, default: true },
    maxVisibleItems: { type: Number, default: 3 },
  };

  connect() {}

  toggle() {
    this.collapsedValue = !this.collapsedValue;
  }

  collapsedValueChanged() {
    this.itemTargets.forEach((item, index) => {
      if (this.collapsedValue && index >= this.maxVisibleItemsValue) {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    });

    console.log("1");

    if (this.collapsedValue) {
      this.shaderTarget.style.display = "block";
      this.toggleButtonTarget.textContent = "See all +";

      console.log("2");
    } else {
      this.shaderTarget.style.display = "none";
      this.toggleButtonTarget.textContent = "See less –";

      console.log("3");
    }
  }
}
