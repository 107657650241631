import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";

const application = Application.start();
window.Stimulus = application;

import AudioPlayerController from "./controllers/audio_player_controller";
application.register("audio-player", AudioPlayerController);

import CarouselController from "./controllers/carousel_controller";
application.register("carousel", CarouselController);

import ExcerptModuleController from "./controllers/excerpt_module_controller";
application.register("excerpt-module", ExcerptModuleController);

import FeaturedResourcesModuleController from "./controllers/featured_resources_module_controller";
application.register(
  "featured-resources-module",
  FeaturedResourcesModuleController
);

import FeaturedUpdatesModuleController from "./controllers/featured_updates_module_controller";
application.register(
  "featured-updates-module",
  FeaturedUpdatesModuleController
);

import LightboxController from "./controllers/lightbox_controller";
application.register("lightbox", LightboxController);

import ResourceListModuleController from "./controllers/resource_list_module_controller";
application.register("resource-list-module", ResourceListModuleController);

import MapAudioComponentController from "../components/map_audio_component_controller";
application.register("map-audio-component", MapAudioComponentController);

import MapAnimationComponentController from "../components/map_animation_component_controller";
application.register(
  "map-animation-component",
  MapAnimationComponentController
);

import MapController from "../components/map_controller";
application.register("map", MapController);

import MapLocationEditorComponentController from "../components/map_location_editor_component_controller";
application.register(
  "map-location-editor-component",
  MapLocationEditorComponentController
);

import MobileHeaderController from "./controllers/mobile_header_controller";
application.register("mobile-header", MobileHeaderController);

import NewsItemFilterController from "./controllers/news_item_filter_controller";
application.register("news-item-filter", NewsItemFilterController);

import NewsItemIndexController from "./controllers/news_item_index_controller";
application.register("news-item-index", NewsItemIndexController);

import NewsItemPagesController from "./controllers/news_item_pages_controller";
application.register("news-item-pages", NewsItemPagesController);

import QuestionModuleController from "./controllers/question_module_controller";
application.register("question-module", QuestionModuleController);

import SvgController from "./controllers/svg_controller";
application.register("svg", SvgController);

import VideoController from "./controllers/video_controller";
application.register("video", VideoController);
