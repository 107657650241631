import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterContainer", "filterHeader", "filterFormContainer"];

  connect() {
    this.isOpen = false;
    this.updateUI();
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.updateUI();
  }

  updateUI() {
    if (this.isOpen) {
      this.filterContainerTarget.classList.add("is-open");
      this.filterFormContainerTarget.classList.remove("hidden");
    } else {
      this.filterContainerTarget.classList.remove("is-open");
      this.filterFormContainerTarget.classList.add("hidden");
    }
  }
}
