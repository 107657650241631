import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nextButton", "prevButton", "slide"];

  static values = {
    currentSlide: Number,
    slideCount: Number,
  };

  connect() {}

  move(direction) {
    let nextSlideIndex =
      (this.currentSlideValue + direction) % this.slideCountValue;

    if (nextSlideIndex < 0) {
      nextSlideIndex = this.slideCountValue - 1;
    }

    this.currentSlideValue = nextSlideIndex;
  }

  prev() {
    this.move(-1);
  }

  next() {
    this.move(1);
  }

  currentSlideValueChanged(index, previousIndex) {
    this.slideTargets[previousIndex].classList.remove("active");
    this.slideTargets[index].classList.add("active");
  }
}
